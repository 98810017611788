:root {
    --color-white: #fff;
    --color-black: #000000;
    --color-white-semitransparent: rgba(255, 255, 255, 0.6);
    --primary-100: rgb(0, 102, 255);
    --primary-200: rgb(247, 159, 31); //
    --primary-300: rgb(0, 198, 224); //
    --accent-100: #cc33cc;
    --accent-200: #fae8ff;
    --telegram: #27a1ff;
    --discord: rgb(87, 79, 193);
    --green: #2ecc71;
    --trans-green: rgba(46, 204, 113, 0.15);

    --color-error: #b84545;
    --color-error-semi: #804124e5;
    --color-info-semi: #ffffffcc;
    --text-100: #fff;
    --text-200: rgb(212, 212, 216);
    --bg-100: #101012;
    --bg-200: #18181b;
    --bg-300: #3f3f46;

    --rt-color-dark: var(--bg-100) !important;
    --rt-opacity: 1 !important;

    --button-gradient: 0px 0px 40px 0px var(--primary-200); //
    --button-gradient-blue: 0px 0px 40px 0px rgba(0, 102, 255, 0.8); //
    --button-gradient-pink: 0px 0px 40px 0px rgba(204, 51, 204, 0.8); //
    --button-gradient-cian: 0px 0px 40px 0px rgba(0, 198, 224, 0.8); //
    --button-gradient-discord: 0px 0px 40px 0px rgba(87, 79, 193, 0.8); //
    --button-gradient-yellow: 0px 0px 40px 0px rgba(247, 159, 31, 0.8); //

    --glass-back: rgba(63, 63, 70, 0.3);

    --card-blue: linear-gradient(180deg, rgba(63, 63, 70, 0.3) 0%, rgba(0, 102, 255, 0.3) 100%);

    --card-pink: linear-gradient(180deg, rgba(204, 51, 204, 0.3) 0%, rgba(63, 63, 70, 0.3) 100%);

    --card-orange: linear-gradient(180deg, rgba(247, 159, 31, 0.3) 0%, rgba(63, 63, 70, 0.3) 100%);

    --texts-width: 480px;

    --headerHeight: 64px; //
    --headerNotificationHeight: 78px; //68 + 10

    --spacer: 48px; //

    --footerHeight: 169px;

    --footerBottom: 64px; //
    font-family: "Inter", sans-serif; //

    color: var(--color-white);
    background-color: var(--bg-100);

    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;

    --h1-size: 48px; //
    --h2-size: 32px; //
    --h3-size: 24px; //
    --h4-size: 20px; //
    --h5-size: 20px; //
    --p-size: 16px; //
    --p2-size: 12px; //

    --h1-lh: 64px; //
    --h2-lh: 48px; //
    --h3-lh: 40px; //
    --h4-lh: 32px; //
    --h5-lh: 32px; //
    --p-lh: 24px; //
    --p2-lh: 18px; //

    --h1-mb: 40px; //
    --h2-mb: 24px; //
    --h3-mb: 24px; //
    --h4-mb: 16px; //
    --h5-mb: 0px; //
    --p-mb: 0px; //

    --note-padding: 24px;
    --note-transparent-padding: 24px;
    --note-background: rgba(247, 159, 31, 0.24);

    --btn-size: 12px;
    --btn-lh: 16px;
    --btn-padding: 16px 24px; //
    --btn-full-w: auto;
    --btn-large-w: fit-content;
    --btn-radius: 56px;

    --list-gap: 24px;
    --list-item-w: auto;

    --cardPadding: 24px 32px 32px 32px; //

    /* ---------------------- MARGINS FOR HEADER AND FOOTER --------------------- */
    --mg-lr: 24px;
    /* -------------------------------------------------------------------------- */

    /* ------------------ MARGINS FOR MAIN SECTIONS (CARDS BORDER) -------------- */
    --mg-main: 8px;
    /* -------------------------------------------------------------------------- */

    /* ---------------------- MARGINS FOR ADDITIONAL MARGINS -------------------- */
    --mbl-mg: 16px;
    /* -------------------------------------------------------------------------- */

    --grid-lr: 0px;

    --icon-size: 32px;
    --section-content-left-margin: 30px;

    --texts-width: 400px;

    --topPadding: 56px;

    --heroBottom: calc(64px + 80px);
    --sectionBottom: 160px; //
    --roadmapBottom: 175px;

    --wcm-z-index: 1001 !important;
    --w3m-z-index: 1000 !important;
    --wui-color-bg-125: var(--bg-200) !important;
}

@media screen and (min-width: 706px) {
    :root {
        --h1-size: 64px; //
        --h2-size: 40px; //
        --h3-size: 32px; //
        --h4-size: 24px; //
        --h5-size: 24px; //

        --h1-lh: 80px; //
        --h2-lh: 56px; //
        --h3-lh: 48px; //
        --h4-lh: 40px; //
        --h5-lh: 40px; //

        --h1-mb: 56px; //
        --h2-mb: 40px; //
        --h4-mb: 24px; //

        --btn-size: 16px;
        --btn-lh: 24px;
        --btn-padding: 16px 40px; //
        --btn-large-w: 320px;
        --btn-full-w: 100%;

        --note-padding: 24px 40px var(--p-mb);
        --note-transparent-padding: 24px 40px;

        /* ---------------------- MARGINS FOR HEADER AND FOOTER --------------------- */
        --mg-lr: 48px;
        /* -------------------------------------------------------------------------- */

        /* ------------------ MARGINS FOR MAIN SECTIONS (CARDS BORDER) -------------- */
        --mg-main: 48px;
        /* -------------------------------------------------------------------------- */

        /* ---------------------- MARGINS FOR ADDITIONAL MARGINS -------------------- */
        --mbl-mg: 0px;
        /* -------------------------------------------------------------------------- */

        --grid-lr: 0px;

        --headerHeight: 88px; //
        --headerNotificationHeight: 92px; //82+10

        --spacer: 64px; //

        --cardPadding: 32px 40px 40px 40px; //

        --footerHeight: 71px;

        --list-item-w: auto;
        --topPadding: 64px;

        --heroTextWidth: 350px;

        --heroBottom: 0;
    }
}

@media screen and (min-width: 1024px) {
    :root {
        --h1-size: 80px; //
        --h2-size: 48px; //

        --h1-lh: 96px; //
        --h2-lh: 64px; //

        --h1-mb: 64px; //
        --h2-mb: 48px; //

        --btn-large-w: fit-content;
        --btn-full-w: auto;
        --section-content-left-margin: 76px;
        --mg-lr: 64px;

        --grid-lr: 64px;

        --texts-width: 480px;
        --heroTextWidth: 480px;

        --headerHeight: 88px;
        --headerNotificationHeight: 100px;

        --spacer: 120px; //

        --footerHeight: 87px;

        --list-item-w: 480px;

        /* ---------------------- MARGINS FOR HEADER AND FOOTER --------------------- */
        --mg-lr: 64px;
        /* -------------------------------------------------------------------------- */

        /* ------------------ MARGINS FOR MAIN SECTIONS (CARDS BORDER) -------------- */
        --mg-main: 64px;
        /* -------------------------------------------------------------------------- */

        /* ---------------------- MARGINS FOR ADDITIONAL MARGINS -------------------- */
        --mbl-mg: 0px;
        /* -------------------------------------------------------------------------- */

        --topPadding: 56px;

        --sectionBottom: 160px;
        --roadmapBottom: 200px;
    }
}

@media screen and (min-width: 1221px) {
    :root {
        --heroBottom: calc(var(--headerHeight) + 100px);
    }
}

/* -------------------------------------------------------------------------- */
/*                                   HEADER                                   */
/* -------------------------------------------------------------------------- */

.header {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    background: none;
    border: solid 1px transparent;
    background-color: transparent;
    transition: all 1s;
    position: fixed;
    width: 100%;
    z-index: 1000 !important;
    top: 0 !important;
    margin-top: 0 !important;
    left: 0;

    a:visited {
        color: inherit;
    }

    &.scrolled {
        border-bottom: solid 1px rgba(255, 255, 255, 0.08);
        background-color: rgba(0, 0, 0, 0.15);
        backdrop-filter: blur(12px);
    }

    .listWrapper {
        .list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            list-style: none;
            gap: 24px;

            li {
                position: relative;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0.01em;
                text-transform: capitalize;
                color: var(--color-white);
                cursor: pointer;

                &:after {
                    content: "";
                    position: absolute;
                    width: 0%;
                    height: 2px;
                    display: block;
                    transition: all 0.5s ease;
                    bottom: -8px;
                    z-index: 100;
                }

                &:hover::after {
                    width: 100%;
                    height: 1px;
                    background-color: var(--color-white);
                }
            }
        }
    }

    .container {
        display: flex;
        // max-width: 1440px;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 16px var(--mg-lr);
        position: relative;

        .logo {
            -webkit-text-stroke: 1px #fff;
            font-family: Mulish, sans-serif;
            font-size: 24px;
            font-weight: 800;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: normal;
            cursor: pointer;
            z-index: 101;

            .arrow {
                margin-right: 16px;
                padding-top: 3px;
                width: 24px;
                height: 24px;
            }
        }
    }
}

@media screen and (min-width: 1230px) {
    .header {
        .listWrapper {
            .list {
                gap: 72px;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .listWrapper {
        display: none;
    }
}

@media screen and (max-width: 705px) {
    .header {
        .container {
            .logo {
                font-size: 20px;
                line-height: 20px;
            }
        }
    }
}
